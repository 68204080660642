import React from 'react';


function Machines() {
  return (
    <div>

    </div>
  );
}

export default Machines;
