import React from 'react';
import './App.css';


import { Fade } from "react-awesome-reveal";

function ImagenConTitulo(props) {
  return (
    
    <div className="imagen-con-titulo">  
      <img src={props.imagenUrl} alt={props.titulo} />
      <h2 className="titulo-services">
        <a href={props.pdfUrl} download={props.pdfFileName}>
          {props.titulo}
        </a>
      </h2>
    </div>
  );
}

function Services() {
  const ourServices = [
    {
      imagenUrl: "img/services/services1.png",
      titulo: "Free maintenance"
    },
    {
      imagenUrl:"img/services/services2.png",
      titulo: "$0 upkeep costs"
    },
    {
      imagenUrl:"img/services/services3.png",
      titulo: "Earn passive income"
    }
  ];

  return (
    <section className="ourServices" id="Services">
        <div className='Our-Services'>OUR SERVICES</div>
        <p className='our-services-txt'><b>Laundry Mates</b> supplies vending machines stocked with laundry and snack products on a profit share arrangement.

For over 20 years we have successfully run both amusement and vending machines in both New Zealand and Australia.

 

<br /><b>Our vending machines allow customers to purchase laundry powder, laundry bags, Fabric Softener, Dryer Sheets as well as confectionary and snack items.</b> 

 

<br />If you own a laundromat in New Zealand and are looking for a proven vending business partner, please make <a href="/#Contact">contact.</a>
        </p>

      <Fade triggerOnce cascade delay={300} >
        <div className="imag-container">
        
          {ourServices.map((item, index) => (
            <ImagenConTitulo
              key={index}
              imagenUrl={item.imagenUrl}
              titulo={item.titulo}
            />
          ))}
        </div>
      </Fade>
    </section>
  );
}

export default Services;
