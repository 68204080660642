import React from 'react';
import './App.css';


import { Fade } from "react-awesome-reveal";

function ImagenConTitulo(props) {
  return (
    
    <div className="imagen-con-titulo">  
      <img src={props.imagenUrl} alt={props.titulo} />
      {/* <h2 className="titulo-services">
        <a href={props.pdfUrl} download={props.pdfFileName}>
          {props.titulo}
        </a>
      </h2> */}
    </div>
  );
}

function Products() {
  const ourServices = [
    {
      imagenUrl: "img/products/1.png",
    //   titulo: "Earn passive income"
    },
    {
      imagenUrl:"img/products/2.png",
    //   titulo: "$0 upkeep costs"
    },
    {
      imagenUrl:"img/products/3.png",
    //   titulo: "Free maintenance"
    },
    {
        imagenUrl:"img/products/4.png",
        // titulo: "Free maintenance"
      }
  ];

  return (
    <section className="ourProduct" id="Products">
        <div className='Our-Services'>OUR PRODUCTS</div>


      <Fade triggerOnce cascade delay={300} >
        <div className="imag-container2">
        
          {ourServices.map((item, index) => (
            <ImagenConTitulo
              key={index}
              imagenUrl={item.imagenUrl}
              titulo={item.titulo}
            />
          ))}
        </div>
        <p className='our-services-txt'><b>Laundry Mates</b> imports its own products for exclusive use in its vending machines.  This ensures we can guarantee the highest standard of quality control during manufacture and packaging.

 

<br />All our products meet New Zealand safety packaging and labeling requirements.  The Soap Powder is low sud, making it rated for both commercial top and front-loading washing machines.
        </p>
      </Fade>
    </section>
  );
}

export default Products;
