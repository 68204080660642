import React from 'react';
import Banner from './banner';
import Services from './services';
import Products from './products';
import Gallery from './gallery';
import Contact from './contact';
// import { Fade } from "react-awesome-reveal";
// import { Slide, Zoom } from "react-awesome-reveal";

function Home() {
  return (
    <div>
      
        <Banner />
      {/* <Fade triggerOnce delay={500}> */}
        <Services />
      {/* </Fade>
      <Fade triggerOnce delay={500}> */}
        <Products />
      {/* </Fade> */}
        <Gallery />
        <Contact />
    </div>
  );
}

export default Home;
