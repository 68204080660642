import React, { useState, useRef } from 'react';
import { MDBFooter, MDBContainer, MDBCol, MDBRow } from 'mdb-react-ui-kit';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import emailjs from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";

export const Contact = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    email_id: '',
    message: '',
  });

  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const recaptchaRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!recaptchaValue) {
      console.error('Please complete the reCAPTCHA.');
      return;
    }

    const serviceId = 'service_dtrqxk5';
    const templateId = 'template_ne28qwq';
    const userId = 'LpwagaB2zdxcjzRVW';

    emailjs.send(serviceId, templateId, formData, userId)
      .then((response) => {
        console.log('Email sent successfully:', response);
        setFormData({
          first_name: '',
          email_id: '',
          message: '',
        });
        setRecaptchaValue(null);
        recaptchaRef.current.reset();
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
  };

  return (
    <section className="contact-section" id="Contact">
      
      <div className='Our-Services'>CONTACT US</div>
      
<div className='contact-container'>
      <div className='contact-txt'>
        <p>Please don't hesitate to send us a message if you have any questions. We are here to help and would be delighted to assist you with any inquiries you may have.</p>
      </div>

      <div className="footer">
        <MDBFooter className='text-body text-center text-lg-left '>
          <MDBContainer className='p-4' style={{ maxWidth: '700px' }}>
            <MDBRow>
              <MDBCol lg='5' md='12' className='mb-4 mb-md-0' id="infoFooter">
                <div className="infocourseFooter" id="infoFooterTitulo2"></div>
                <div className='contactInfo'>
                  <div className='info-email'>
                    <h4 style={{ marginBottom: '0.3rem' }}>Email to us</h4>
                    <h6 style={{ marginBottom: '0.2rem' }}>info@laundrymates.nz</h6>
                  </div>
                </div>
              </MDBCol>

              <MDBCol lg='7' md='12' className='mb-4 mb-md-0'>
                <div className='registerForm'>
                  <div className='formApply'>
                    <Form onSubmit={handleSubmit}>
                      <Form.Group className="mb-4" as={Col} controlId="validationCustom01">
                        <Form.Control
                          required
                          type="text"
                          placeholder="Name"
                          feedback="Please write your first name."
                          name="first_name"
                          value={formData.first_name}
                          onChange={handleChange}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please write your name
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-4" as={Col} controlId="validationCustomUsername">
                        <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                          <Form.Control
                            type="email"
                            placeholder="Email"
                            aria-describedby="inputGroupPrepend"
                            required
                            name="email_id"
                            value={formData.email_id}
                            onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please choose an email.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>

                      <Form.Group className="mb-4" as={Col} controlId="message">
                        <InputGroup hasValidation>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            type="text"
                            placeholder="Message"
                            aria-describedby="inputGroupPrepend"
                            name="message"
                            required
                            value={formData.message}
                            onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please write a message.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>

                      <Form.Group className="mb-3 d-flex justify-content-end" controlId="checkBox1" style={{ color: "white" }}>
                      {/* <ReCAPTCHA sitekey='6Le0MU8oAAAAAM4zQ8AMbN6ylaqWJP8w-3MwrqMA' onChange={handleRecaptchaChange} /> */}
{/* recaptcha for website */}
                        <ReCAPTCHA sitekey='6Ld3kRYpAAAAAOEQFib0PLSeroQdhWEMAW28L9Dl' onChange={handleRecaptchaChange} />
                      </Form.Group>

                      <div className='btnForm' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button id="btn" type="submit">Send</Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBFooter>
      </div>
      </div>
    </section>
  );
};

export default Contact;
