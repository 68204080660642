import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';

function Header() {
  
  return (
    <div className="header-background"> 

        <Navbar collapseOnSelect expand="lg"  variant="dark" className='custom-navbar'>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mx-auto custom-font">    
            <Nav.Link className='nav3' id='link-header' href="/#Services">SERVICES</Nav.Link>
            <Nav.Link className='nav3' id='link-header' href="/#Products">PRODUCTS</Nav.Link>
              <Nav.Link className='nav3' id='link-header' href="/#Gallery">GALLERY</Nav.Link>
              <Nav.Link className='nav3' id='link-header' href="/#Contact">CONTACT</Nav.Link>
            </Nav>
        </Navbar.Collapse>
        </Navbar>
       
    </div>
  );
}

export default Header;



